<template>
  <div>
    <div id="branchFilter" class="branchFilter">
      <div>
        <h4>สาขาใกล้บ้าน</h4>
        <v-select v-model="filter.region" :items="filter.regions" item-text="desc" item-value="value" label="Select" persistent-hint single-line @change="selectRegion()"></v-select>
        <v-select v-model="filter.province" :items="provincesFilter" item-text="desc" item-value="value" label="Select" persistent-hint single-line></v-select>
        <v-layout style="height:30px;justify-content: center;align-items: center;">
          <v-slider v-model="filter.distance" max="2000" :thumb-size="24" thumb-label="always"></v-slider>
          <v-checkbox v-model="filter.distanceDescending" label="มากไปน้อย"></v-checkbox> 
        </v-layout>
      </div>
      <h4>สาขา ({{dataBranchFilter.length}})</h4>
      <v-container style="height: 500px; overflow-y: scroll">
        <v-card class="mx-auto" max-width="250" v-for="branch in dataBranchFilter" :key="branch.branch_name" @click="selectBranch(branch)">
          <v-card-text>
            <div class="text--primary">{{branch['company_name']}}<br>{{branch['branch_name']}} ({{branch['distance']}} km)</div>
            <br>
            <div>
              {{branch['address']}}
              {{branch['sub_district']}} {{branch['district']}} {{branch['province']}} {{branch['zipcode']}}<br>
              {{branch['phone']}} {{branch['mobile']}}<br>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </div>
    <br>
    <gmap-map ref="myMapRef" :center="center" :zoom="15" style="width: 100%; height: 600px">
      <!-- <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
      </gmap-info-window>

      <gmap-marker :key="i" v-for="(m,i) in markers" :position="m.position" :clickable="true" @click="toggleInfoWindow(m,i)"></gmap-marker> -->
      <gmap-marker
        :key="index"
        v-for="(location, index) in data.dataBranch"
        :position="{ lat: location.latitude, lng: location.longitude }"
        :clickable="true"
        @click="openInfoWindow(location);"
      />
      <gmap-info-window
        v-if="selectedLocation !== null"
        :position="{ lat: selectedLocation.latitude, lng: selectedLocation.longitude }"
        :opened="infoBoxOpen"
        @closeclick="infoBoxOpen = false;"
      >
        <div class="infoWindow" style="width: 300px;">
          <img :src="`data:image/png;base64, ${selectedLocation['branch_pic_base64']}`" height="160" weight="160">
              <div class="iw-subTitle">{{selectedLocation['company_name']}} {{selectedLocation['branch_name']}}</div>
              <p>{{selectedLocation['address']}}{{selectedLocation['sub_district']}} {{selectedLocation['district']}} {{selectedLocation['province']}} {{selectedLocation['zipcode']}}
              {{selectedLocation['phone']}} {{selectedLocation['mobile']}}
              </p>
              <v-btn outlined color="primary" style="text-transform: capitalize" @click="viewBranch(selectedLocation)">View Detail</v-btn>
              <!-- <button outlined color="primary" class="button-iw" @click="viewBranch(selectedLocation)">View Detail</button> -->
        </div>
      </gmap-info-window>
    </gmap-map>
  </div>
</template>
<script>
export default {
  name: 'GoogleMap',
  data () {
    return {
      filter: {
        region: '00',
        province: '',
        regions: [
          { value: '00', desc: 'ทั้งหมด' },
          { value: '01', desc: 'ภาคกลาง' },
          { value: '02', desc: 'ภาคเหนือ' },
          { value: '03', desc: 'ภาคตะวันออกเฉียงเหนือ' },
          { value: '04', desc: 'ภาคตะวันออก' },
          { value: '05', desc: 'ภาคตะวันตก' },
          { value: '06', desc: 'ภาคใต้' },
        ],
        provinces: [
          { value: 'กรุงเทพ', region: '01', desc: 'กรุงเทพ' },
          { value: 'นนทบุรี', region: '01', desc: 'นนทบุรี' },
          { value: 'สมุทรปราการ', region: '01', desc: 'สมุทรปราการ' },
          { value: 'ชลบุรี', region: '04', desc: 'ชลบุรี' },
          { value: 'สระบุรี', region: '01', desc: 'สระบุรี' },
          { value: 'เชียงใหม่', region: '02', desc: 'เชียงใหม่' },
          { value: 'แพร่', region: '02', desc: 'แพร่' },
        ],
        distance: 1000,
        distanceDescending: false,
      },
      utils: require('@/utils'),
      data: {
        dataBranch: [],
      },
      pic_base64: '',
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: 13.722025, lng: 100.70805 },
      markers: [],
      places: [],
      selectedLocation: null,
      infoBoxOpen: false,
      currentPlace: null,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
        shouldFocus: false,
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
    }
  },
  computed: {
    provincesFilter () {
      return this.filter.region === '00' ? this.filter.provinces : this.filter.provinces.filter(x => {
        return x.region === this.filter.region
      })
    },
    dataBranchFilter () {
      let filterData = this.filter.province === '' ? this.data.dataBranch : this.data.dataBranch.filter(x => {
        return x.province === this.filter.province
      })
      filterData = filterData.filter(x => {
        return x.distance <= this.filter.distance
      })
      return filterData.sort((a, b) => {
        return this.filter.distanceDescending ? b.distance - a.distance : a.distance - b.distance
      })
    }
  },
  async created () {
    await this.getGsbBranch()
    this.geolocate()
  },
  mounted () {
    this.$refs.myMapRef.$mapPromise.then((map) => {
      const centerControlDiv = document.createElement('div')
      this.addCenterControl(centerControlDiv, map)
      map.controls[google.maps.ControlPosition.TOP_CENTER].push(
        centerControlDiv
      )
    })
  },
  methods: {
    openInfoWindow (location) {
      this.selectedLocation = location
      this.infoBoxOpen = true
    },
    selectRegion () {
      this.filter.province = ''
    },
    viewBranch (branch) {
      alert(branch['address'])
      this.infoBoxOpen = false
    },
    selectBranch (branch) {
      this.openInfoWindow(branch)
      this.center = {
        lat: branch.latitude,
        lng: branch.longitude
      }
    },
    addCenterControl (controlDiv, map) {
      // const controlUI = document.createElement('div')

      // controlUI.innerHTML = `
      //   <div style="color: white; background: red; padding: 1rem;">
      //     You can click me, i can also be a vue component
      //   </div>
      // `;
      // controlDiv.appendChild(controlUI);
      // controlUI.addEventListener('click', () => {
      //   // Do what ever you want to happen on click event
      //   map.setCenter({
      //     lat: 53.57532,
      //     lng: 10.01534,
      //   })
      // })
      const input = document.getElementById('branchFilter')
      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(input)
    },
    setPlace (place) {
      this.currentPlace = place
    },
    addMarker () {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        }
        this.markers.push({ position: marker })
        this.places.push(this.currentPlace)
        this.center = marker
        this.currentPlace = null
      }
    },
    geolocate () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        for (var branch of this.data.dataBranch) {
          branch.distance = this.utils.getDistanceBetweenPoints(position.coords.latitude, position.coords.longitude, branch['latitude'], branch['longitude']).toFixed(2)
          branch.url = `https://www.google.com/maps/place/${branch.latitude},${branch.longitude}`
        }
      })
    },
    toggleInfoWindow (marker, idx) {
      this.center = marker.position
      this.infoWindowPos = marker.position
      this.infoOptions.content = marker.infoText

      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    async getGsbBranch () {
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/test/getGsbBranch' + process.env.VUE_APP_DATABASE_TYPE, {
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.data.dataBranch = response.data.data
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
  }
}
</script>
<style>
 #map-canvas {
	margin: 0;
	padding: 0;
	height: 400px;
	max-width: none;
}
#map-canvas img {
	max-width: none !important;
}
.gm-style-iw {
	width: 350px !important;
	top: 15px !important;
	left: 0px !important;
	background-color: #fff;
	box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
	border: 1px solid rgba(72, 181, 233, 0.6);
	border-radius: 2px 2px 10px 10px;
}
#iw-container {
	margin-bottom: 10px;
}
#iw-container .iw-title {
	font-family: 'Open Sans Condensed', sans-serif;
	font-size: 22px;
	font-weight: 400;
	padding: 10px;
	background-color: #48b5e9;
	color: white;
	margin: 0;
	border-radius: 2px 2px 0 0;
}
#iw-container .iw-content {
	font-size: 13px;
	line-height: 18px;
	font-weight: 400;
	margin-right: 1px;
	padding: 15px 5px 20px 15px;
	max-height: 140px;
	overflow-y: auto;
	overflow-x: hidden;
}
.iw-content img {
	float: right;
	margin: 0 5px 5px 10px;	
}
.iw-subTitle {
	font-size: 16px;
	font-weight: 700;
	padding: 5px 0;
}
.iw-bottom-gradient {
	position: absolute;
	width: 326px;
	height: 25px;
	bottom: 10px;
	right: 18px;
	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}
.button-iw {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.branchFilter {
  background-color: #ffffff; /* Green */
  border: none;
  color: rgb(231, 65, 79);
  padding: 15px 32px;
  /* padding-right: 80px; */
  margin-top: 20px;
  margin-right: 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
</style>